<template>
	<div class="header">
		<div class="header__inner">
			<p>摩托车交强险投保</p>
			— 便民服务站 —
		</div>
	</div>

	<div class="blist">
		<div class="blist__head">选择投保公司</div>

		<div class="blist__body" ref="scrollRef">
			<div class="scroll">
				<div class="refresh-txt">
					<van-icon name="down" v-if="reFreshStatus == 'moving'"
						>下拉刷新</van-icon
					>
					<van-icon name="back-top" v-else-if="reFreshStatus == 'default'"
						>松开刷新</van-icon
					>
					<van-loading size="24px" type="spinner" v-else-if="fetching"
						>正在加载</van-loading
					>
					<span v-else>加载完成</span>
				</div>
				<div
					@click="toDetail(item)"
					class="blist__body__item"
					v-for="item in list"
					:key="item.id"
				>
					<div
						class="logo"
						:style="{
							backgroundImage: `url(${item.url})`
						}"
					/>
					{{ item.name }}
				</div>
				<div class="has-more">
					<van-loading size="32px" v-show="pullUpStatus == 'loading'"
						>正在加载</van-loading
					>
					<span v-show="pullUpStatus == 'no-more'">没有更多了</span>
				</div>
			</div>
		</div>
	</div>

	<van-floating-bubble
		axis="xy"
		magnetic="x"
		:offset="floatButtonOffset"
		@click="toFeedback"
	>
		<p class="floating-bubble">问题<br />反馈</p>
	</van-floating-bubble>
</template>

<script>
// @ is an alias to /src
import { ApiInsuranceList, ApiClicksave } from '@/api'
import useScroll from '@/composition/use-scroll'
import { showLoadingToast, showToast } from 'vant'
import { ref } from 'vue'
export default {
	name: 'HomeView',
	setup() {
		const scrollRef = ref(null)
		const { scroll } = useScroll(scrollRef, {
			pullUpLoad: true,
			pullDownRefresh: { threshold: 60, stop: 40 }
		})
		return { scrollRef, scroll }
	},
	data() {
		return {
			list: [],
			page: 1,
			total: Infinity,
			pullUpStatus: 'more', // 上滑加载的状态
			reFreshStatus: 'default', // 下拉刷新的状态
			fetching: false // 正在获取数据
		}
	},
	mounted() {
		// 上滑加载
		this.scroll.on('pullingUp', () => {
			if (this.pullUpStatus !== 'more') {
				this.scroll.finishPullUp()
				return
			}
			this.pullUpStatus = 'loading'
			this.page++
			this.fetchList().finally(this.scroll.finishPullUp)
			// 需配合finishPullUp方法使用, 调用该方法恢复监听
		})

		// 下拉刷新
		this.scroll.on('enterThreshold', () => {
			this.reFreshStatus = 'moving'
		})
		this.scroll.on('leaveThreshold', () => {
			this.reFreshStatus = 'default'
		})
		this.scroll.on('pullingDown', () => {
			this.reFreshStatus = 'fetching'
			this.refresh().finally(() => {
				setTimeout(() => {
					// 需配合finishPullDown方法使用, 调用该方法恢复监听
					this.scroll.finishPullDown()
				}, 500)
			})
		})
		this.scroll.autoPullDownRefresh()
	},
	computed: {
		floatButtonOffset() {
			const y = (document.documentElement.clientHeight * 3) / 4
			return { y }
		},
		//下拉显示文字
		reFreshNav() {
			return {
				moving: {
					icon: 'down',
					text: '下拉刷新'
				},
				default: {
					icon: 'back-top',
					text: '松开刷新'
				},
				fetching: {
					icon: 'loading',
					text: '正在刷新'
				}
			}[this.reFreshStatus]
		}
	},
	methods: {
		fetchList() {
			this.fetching = true
			return ApiInsuranceList({
				page: this.page
			})
				.then((res) => {
					this.total = res.page.totalCount
					this.list.push(...res.page.list)
				})
				.finally(() => {
					this.fetching = false
					this.scroll.refresh()
					this.pullUpStatus = this.list.length < this.total ? 'more' : 'no-more'
				})
		},
		refresh() {
			this.page = 1
			this.list = []
			this.pullUpStatus = 'more'
			return this.fetchList()
		},
		toFeedback() {
			this.$router.push({ name: 'feedback' })
		},
		toDetail(insurance) {
			const loading = showLoadingToast({
				message: '加载中...',
				forbidClick: true
			})
			this.saveClick(insurance).finally(() => {
				loading.close()
				if (insurance.address) {
					location.href = insurance.address
				} else {
					showToast(`${insurance.name}暂无投保链接，敬请期待~`)
				}
			})
		},
		// 记录点击
		saveClick(insurance) {
			return ApiClicksave(insurance)
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	height: 300px;
	background-color: #1651dc;
	background-image: url(@/assets/street.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 110% auto;
	margin-bottom: -$space_4;
	padding-top: 50px;

	&__inner {
		font-family: 'ZCoo GaoduanHei';
		font-size: $font_large;
		color: #fff;
		height: 150px;
		padding: 20px 50px 0;
		// background: #90afb1;
		// text-align: right;
		// mix-blend-mode: screen;
		text-shadow: 0 0 25rem #000;
		p {
			text-align: left;
			line-height: 1.5;
			font-size: 58px;
			font-style: italic;
			// text-shadow: 0 0 24px rgba(0, 0, 0, 0.6);
		}
	}
}

.blist {
	position: relative;
	padding-top: $space_3;
	border-radius: $border_radius_6 $border_radius_6 0 0;
	background-image: linear-gradient(top, #e6f6ff, #fff 100px);

	&__head {
		padding: $space_2 $space_5;
		overflow: hidden;
	}

	&__body {
		height: calc(100vh - 360px);
		overflow: hidden;

		.scroll {
			display: grid;
			align-content: start;
			grid-template-columns: repeat(2, 318px);
			gap: $space_3;
			position: relative;
			padding: $space_2 $space_5;

			.has-more {
				text-align: center;
				padding: $space_2;
				grid-column: 1 / -1;
				font-size: $font_medium;
				color: $color_text_l;
			}

			.refresh-txt {
				font-size: $font_medium;
				color: $color_text_l;
				position: absolute;
				left: 50%;
				top: -50px;
				white-space: nowrap;
				transform: translateX(-50%);
			}
		}

		&__item {
			box-shadow: $box_shadow_3;
			height: 120px;
			width: 100%;
			display: inline-flex;
			align-items: center;
			font-size: $font_medium;

			.logo {
				width: 75px;
				height: 70px;
				margin-left: 30px;
				margin-right: 20px;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}
}

.floating-bubble {
	font-size: $font_medium;
	line-height: 1.2;
	letter-spacing: 2px;
}
</style>
