import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 按需引入vant组件
import { Button } from 'vant'
import 'vant/es/button/style'
import { Form } from 'vant'
import 'vant/es/form/style'
import { Field } from 'vant'
import 'vant/es/field/style'
import { Picker } from 'vant'
import 'vant/es/picker/style'
import { Popup } from 'vant'
import 'vant/es/popup/style'
import { Icon } from 'vant'
import 'vant/es/icon/style'
import { Toast } from 'vant'
import { Loading } from 'vant'
import 'vant/es/loading/style'
import { Lazyload } from 'vant'
import 'vant/es/lazyload/style'
import { CellGroup } from 'vant'
import 'vant/es/cell-group/style'
import 'vant/es/toast/style'
import { Dialog } from 'vant'
import 'vant/es/dialog/style'
import { Notify } from 'vant'
import 'vant/es/notify/style'
import { Cascader } from 'vant'
import 'vant/es/cascader/style'
import { Space } from 'vant'
import 'vant/es/space/style'
import { FloatingBubble } from 'vant'
import 'vant/es/floating-bubble/style'
import { ImagePreview } from 'vant'
import 'vant/es/image-preview/style'

import '@/postrem' // 设置postcss-px2rem基础大小

// import(/* webpackChunkName: "npm.vconsole" */ 'vconsole').then(
// 	(data) => new data.default()
// )
import '@/assets/scss/index.scss'
// import '@/assets/fonts/SourceHanSansSC/index.css' // 思源柔黑
import '@/assets/fonts/Zcool/index.css' // 站酷字体
// import '@/assets/fonts/GenJyuuGothic/index.css' // 思源柔黑

createApp(App)
	.use(store)
	.use(router)

	.use(Button)
	.use(Field)
	.use(Form)
	.use(CellGroup)
	.use(Cascader)
	.use(Lazyload)
	.use(Loading)
	.use(Icon)
	.use(Toast)
	.use(Dialog)
	.use(Notify)
	.use(ImagePreview)
	.use(Space)
	.use(FloatingBubble)
	.use(Picker)
	.use(Popup)
	.mount('#app')
