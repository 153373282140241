import fetch from './fetch'

export function ApiInsuranceOptions() {
	return fetch({
		url: 'renren-fast/insurance/expose/list',
		method: 'get'
	})
}

export function ApiInsuranceList(params) {
	return fetch({
		url: 'renren-fast/insurance/expose/lists',
		method: 'get',
		params
	})
}
export function ApiBacksave(data) {
	return fetch({
		url: 'renren-fast/insurance/expose/backsave',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		}
	})
}

export function ApiClicksave(data) {
	return fetch({
		url: 'renren-fast/insurance/expose/clicksave',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		}
	})
}
