import axios from 'axios'
import qs from 'qs'
import { showDialog, showToast } from 'vant'

axios.defaults.headers.post['Content-Type'] =
	'application/x-www-form-urlencoded;charset=UTF-8'
// 创建axios实例

const service = axios.create({
	baseURL: '/api', // api 的 base_url
	timeout: 600000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
	async (config) => {
		const flag =
			config.headers['Content-Type']?.indexOf('application/json') !== -1
		if (!flag) {
			const mult =
				config.headers['Content-Type']?.indexOf('multipart/form-data') !== -1
			if (mult) {
				config.data = config.data
			} else {
				config.data = qs.stringify(config.data)
			}
		}
		return config
	},

	(error) => {
		showToast({
			message: '啊哦，服务器飞到外太空了~'
		})
		return Promise.reject(error)
	}
)

// response 拦截器
service.interceptors.response.use(
	(response) => {
		const res = response.data
		if (response.status === 200 && response.config.responseType === 'blob') {
			// 文件类型特殊处理
			return response
		} else if (res.code == 0) {
			return res
		} else {
			res.msg && showToast(res.msg)
			return Promise.reject(res)
		}
	},
	(error) => {
		showToast('啊哦，服务器飞到外太空了~')
		return Promise.reject(error)
	}
)

export default service
