import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/home.vue'
import Introduce from '../views/introduce.vue'

const routes = [
	{
		path: '/',
		name: 'introduce',
		component: Introduce,
		meta: {
			title: '平台声明'
		}
	},
	{
		path: '/home',
		name: 'home',
		component: HomeView,
		meta: {
			title: '自主投保'
		}
	},
	{
		path: '/feedback',
		name: 'feedback',
		component: () => import('../views/feedback.vue'),
		meta: {
			title: '问题反馈'
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
