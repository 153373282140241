import BScroll from '@better-scroll/core'
import ObserveDOM from '@better-scroll/observe-dom'
import PullDown from '@better-scroll/pull-down'
import PullUp from '@better-scroll/pull-up'
import { onActivated, onDeactivated, onMounted, onUnmounted, ref } from 'vue'

BScroll.use(ObserveDOM)
BScroll.use(PullUp)
BScroll.use(PullDown)

export default function (scrollRef, options = {}, emit) {
	let scroll = ref(null),
		scrollVal

	// 开启下拉刷新默认配置
	let { pullDownRefresh } = options
	pullDownRefresh =
		pullDownRefresh === true ? { threshold: 90, stop: 70 } : pullDownRefresh

	onMounted(() => {
		scrollVal = scroll.value = new BScroll(scrollRef.value, {
			observeDOM: true,
			click: true,
			tap: true,
			...options,
			pullDownRefresh
		})
	})
	onUnmounted(() => {
		scrollVal.destroy()
	})
	onDeactivated(() => {
		scrollVal.disable()
	})
	onActivated(() => {
		scrollVal.enable()
		scrollVal.refresh()
	})

	return { scroll }
}
