const baseWidth = 750

function rem() {
	document.documentElement.style.fontSize =
		document.documentElement.clientWidth / baseWidth + 'px'
}

window.onresize = rem

rem()
