<template>
	<div class="title">
		厦门市摩托车交强险投保
		<div class="title__sub">便民平台说明</div>
	</div>
	<div class="art">
		1.本平台目前仅支持为持有<em>厦门地区</em>机动车行驶证的<em>国产燃油</em>摩托车自然人车主提供<em>交强险</em>便民投保服务，<em>电动、进口</em>及其他摩托车车主请<em>自行联系</em>当地财产保险公司咨询投保方式或通过平台<em>问题反馈按钮</em>进行反馈。<br />
		2.本平台<em>暂时无法识别免车船税车辆</em>，若您符合且需要免车船税投保，请<em>自行线下联系</em>当地财产保险公司投保。<br />
		3.本平台将陆续接入各财产保险公司，若您的意向投保公司不在平台列表内，请自行线下联系当地财产保险公司投保。<br />
		4.为保证车主的权益，若投保人、被保人与车主不一致的，请自行线下联系当地财产保险公司投保。<br />
		5.本平台通过链接的方式接入各财产保险公司投保界面，具体的投保操作流程以各财产险公司系统为准。<br />
		6.根据《机动车交通事故责任强制保险条例》第十五条规定，特提示如下：为避免影响后续理赔，请投保人在投保时应当对被保险机动车信息履行<em>如实告知</em>义务，按照要求填写信息、提供材料，并<em>确保信息及材料完整准确</em>。<br />
		7.本平台目前处于试运行阶段，各项功能有待完善，如您在投保过程中遇到问题，可通过平台“问题反馈”模块或财产保险公司官方客服具体反映。我们将督促财产保险公司优化系统、逐步完善摩托车客户投保需求！
	</div>
	<div class="footer__placeholder"></div>
	<div class="footer">
		<van-button
			:disabled="disabled"
			round
			size="large"
			block
			type="primary"
			@click="toHome"
		>
			{{ second ? `请阅读声明 ${second}s` : '下一步' }}</van-button
		>
	</div>
</template>

<script>
export default {
	data() {
		return {
			second: 10
		}
	},
	computed: {
		disabled() {
			return this.second > 0
		}
	},
	mounted() {
		const secondTimer = setInterval(() => {
			this.second--
			if (this.second == 0) clearInterval(secondTimer)
		}, 1000)
	},
	methods: {
		toHome() {
			this.$router.push({ name: 'home' })
		}
	},
	components: {}
}
</script>

<style lang="scss" scoped="scoped">
.title {
	padding-top: 40px;
	font-size: 60px;
	text-align: center;
	line-height: 1.5;
	&__sub {
		font-size: $font_medium_x;
		color: #000;
	}
}
.art {
	color: $color_text_ll;
	font-size: $font_medium;
	padding: 60px $space_4;
	line-height: 1.8;
	em {
		font-style: normal;
		color: $color_err;
	}
}

.footer {
	background-color: #fff;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	padding: $space_3 $space_4;
	border: none !important;

	&__placeholder {
		height: 140rem;
	}
}
</style>
